import { Button, Flex, Image, Text } from '@podiumhq/design-system';

import icon from './unexpected-error.svg';

const ErrorView = () => (
  <Flex
    align="center"
    direction="column"
    height="100%"
    justify="center"
    textAlign="center"
    width="100%"
  >
    <Image src={icon} alt="Error" width="168px" height="auto" />

    <h1>Uh oh!</h1>

    <Text fontSize="md" fontWeight="normal" paddingBottom="30px">
      Hmmm... looks like something went wrong on our end.
    </Text>

    <Button variant="primary" onClick={() => window.location.reload()}>
      Reload Page
    </Button>
  </Flex>
);

export default ErrorView;
