import env from 'env';

export const fetchIpAddress = async () => {
  const ipAddressResponse = await fetch(
    `${env.IPSTACK_URL}/check?access_key=${env.IPSTACK_TOKEN}`
  );

  const ipAddress = await ipAddressResponse.json();

  return ipAddress?.ip;
};
