const SALES_ASSIST_TRACKING_EVENTS = {
  salesAssist: {
    formFieldFilled: true,
    viewed: true,
    clicked: true,
    error: true,
    action: true,
  },
};

export default SALES_ASSIST_TRACKING_EVENTS;
