const MOCK_REVIEW_INVITE_EVENTS = {
  mockReviewInvite: {
    continueWithGoogle: {
      viewed: true,
      continueClicked: true,
    },
    leaveRating: {
      viewed: true,
      ratingClicked: true,
      postCtaClicked: true,
      backCtaClicked: true,
    },
    reviewConfirmation: {
      viewed: true,
    },
  },
};

export default MOCK_REVIEW_INVITE_EVENTS;
