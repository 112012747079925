const MOCKED_COOKIE = 'mockIdToken';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  return parts.length === 2 && parts.pop().split(';').shift();
};

const isMocked = () => !!getCookie(MOCKED_COOKIE);

export default isMocked;
