import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import env from 'env';

import { getIdToken } from '../../getIdToken';

const httpLink = createHttpLink({
  uri: `${env.SUBSCRIPTIONS_URI}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const idToken = await getIdToken(env.ACCOUNTS_URL);
  if (!idToken) throw new Error('No idToken returned from auth library');
  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
