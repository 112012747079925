import { datadogRum } from '@datadog/browser-rum';

const addDatadog = () => {
  if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'checkout',
      env: process.env.NODE_ENV,
      version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      premiumSampleRate: 0,
      defaultPrivacyLevel: 'allow',
    });
  }
};

export default addDatadog;
