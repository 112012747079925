import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@podiumhq/design-system';

import subscriptionsClient from 'lib/apollo/clients/subscriptions';

import ErrorView from './components/ErrorView';
import { ErrorBoundary } from './ErrorBoundary';
import RoutesComponent from './routes';

const App = () => (
  <ErrorBoundary fallback={<ErrorView />}>
    <ApolloProvider client={subscriptionsClient}>
      <ThemeProvider resetCSS>
        <RoutesComponent />
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
