import { getCookies } from './getCookies';

let idTokenPromise = null;
let refreshingToken = false;

const decodeToken = (idToken) => {
  if (idToken) {
    const tokenParts = idToken.split('.');
    return JSON.parse(atob(tokenParts[1]));
  }

  return null;
};

const getCookieDomain = (hostname) => {
  const domain = hostname.replace(/[^.]+\./, '');

  return domain === 'localhost' ? domain : '.' + domain;
};

export const createCookie = ({ cookieName, cookieValue, hostname } = {}) => {
  if (!cookieName || !cookieValue || !hostname) {
    return '';
  }

  const domain = getCookieDomain(hostname);
  const secureCookie = window.location.protocol === 'https:';

  return `${cookieName}=${cookieValue}; Domain=${domain}; path=/; Secure==${secureCookie}; SameSite=Lax`;
};

export const createTokenCookie = ({
  cookieName = 'idToken',
  hostname,
  tokenValue,
} = {}) => {
  const exp = decodeToken(tokenValue).exp;
  const expiration = new Date(exp * 1000);
  const cookieString = createCookie({
    cookieName,
    cookieValue: tokenValue,
    hostname,
  });

  return `${cookieString}; Expires=${expiration.toUTCString()}`;
};

const deleteCookie = (cookie, hostname) => {
  const expiration = new Date(0);
  const domain = getCookieDomain(hostname);

  document.cookie =
    cookie + '=; Expires=' + expiration.toUTCString() + '; Domain=' + domain;
};

const isNotExpired = (idToken) => {
  const decodedToken = decodeToken(idToken);
  if (!decodedToken) {
    return false;
  }

  const date = decodedToken.exp ? decodedToken.exp : null; // convert to millis

  return new Date(date * 1000) > Date.now();
};

const generateXRequestId = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
  const host = window.location.host;
  const appName = host.substring(0, host.length - 4);

  return ''.concat(appName, '-').concat(uuid);
};

const getRefreshTokenUrl = (accountsUrl) =>
  `${accountsUrl}/api/refresh_token/v3`;

const getRefreshToken = async (accountsUrl) => {
  try {
    refreshingToken = true;

    const token = getCookies().refreshToken;
    const headers = {
      'Content-Type': 'application/json',
      'x-request-id': generateXRequestId(),
    };
    const params = {
      method: 'POST',
      headers: headers,
      redirect: 'follow',
      body: JSON.stringify({ token }),
    };

    const idTokenResponse = await fetch(
      getRefreshTokenUrl(accountsUrl),
      params
    );
    const idToken = await idTokenResponse.text();

    refreshingToken = false;
    document.cookie = createTokenCookie({
      hostname: window.location.hostname,
      tokenValue: idToken,
    });

    return idToken;
  } catch (error) {
    refreshingToken = false;
  }
};

const getIdTokenPromise = (ignoreMock) =>
  new Promise((resolve, reject) => {
    const cookies = getCookies();
    const { idToken, mockIdToken } = cookies;
    const validMockIdToken =
      !ignoreMock && mockIdToken && isNotExpired(mockIdToken)
        ? mockIdToken
        : null;
    const validIdToken = idToken && isNotExpired(idToken) ? idToken : null;
    const authIdToken = validMockIdToken || validIdToken;

    if (validMockIdToken) {
      deleteCookie('mockIdToken', window.location.hostname);
    }

    if (authIdToken) {
      resolve(authIdToken);
    } else {
      reject('not authenticated');
    }
  });

export const getIdToken = async (accountsUrl, ignoreMock) => {
  if (idTokenPromise === null) {
    idTokenPromise = getIdTokenPromise(ignoreMock);
  }

  try {
    const idToken = await idTokenPromise;
    if (isNotExpired(idToken)) return idToken;
  } catch (e) {
    if (!refreshingToken) {
      return await getRefreshToken(accountsUrl);
    }
  }
};
