import { datadogRum } from '@datadog/browser-rum';
import { fetchIpAddress } from './ipAddress';

export const addErrorToDatadog = (errorMessage) => {
  if (!errorMessage) {
    return;
  }

  fetchIpAddress().then((ipAddress) =>
    datadogRum.addError(new Error(errorMessage), { ipAddress })
  );
};
