import env from 'env';
import { experiment, tracking } from '@podiumhq/tracking';

import SELECT_PLAN_CHECKOUT_TRACKING_EVENTS from 'screens/Checkout/trackingEvents';
import PRICING_PACKAGING_EVENTS from 'screens/PricingPackaging/trackingEvents';
import SALES_ASSIST_TRACKING_EVENTS from 'screens/SalesAssist/trackingEvents';
import TRIALS_TRACKING_EVENTS from 'screens/Trials/trackingEvents';
import MOCK_REVIEW_INVITE_EVENTS from 'screens/MockReviewInvite/trackingEvents';
import STARTER_UPGRADE_TRACKING_EVENTS from 'screens/StarterUpgrade/trackingEvents';
import CART_CHECKOUT_TRACKING_EVENTS from 'screens/PurchaseCart/trackingEvents';
import PLAN_CART_CHECKOUT_TRACKING_EVENTS from 'screens/PurchasePlanCart/trackingEvents';

const TRACKING_EVENTS = {
  ...CART_CHECKOUT_TRACKING_EVENTS,
  ...MOCK_REVIEW_INVITE_EVENTS,
  ...PRICING_PACKAGING_EVENTS,
  ...SALES_ASSIST_TRACKING_EVENTS,
  ...SELECT_PLAN_CHECKOUT_TRACKING_EVENTS,
  ...STARTER_UPGRADE_TRACKING_EVENTS,
  ...TRIALS_TRACKING_EVENTS,
  ...PLAN_CART_CHECKOUT_TRACKING_EVENTS,
};

const attributionConfig = {
  includeReferrer: true,
  includeUtm: true,
  includeGclid: true,
};

export default function setupTracking() {
  const isDevelopment = process.env.NODE_ENV === 'development';

  tracking.init({
    enabled: true,
    events: TRACKING_EVENTS,
    debug: isDevelopment,
    amplitudeToken: env.AMPLITUDE_TOKEN,
    amplitudeConfig: {
      ...attributionConfig,
      batchEvents: false,
      saveEvents: true,
    },
  });

  if (env.AMPLITUDE_EXPERIMENT_TOKEN) {
    experiment.init({
      connectToAnalytics: true,
      debug: isDevelopment,
      enabled: true,
      environmentKey: env.AMPLITUDE_EXPERIMENT_TOKEN,
    });
  }
}
