import env from 'env';

export const PAYMENT_PATHNAME = '/pay';

export const PODIUM_APP_URL = 'https://app.podium.com/';
export const PODIUM_URL = 'https://www.podium.com';
export const PODIUM_PRIVACY_POLICY_URL =
  'https://legal.podium.com/#privacypolicy-us';
export const PODIUM_TERMS_AND_CONDITIONS_URL =
  'https://legal.podium.com/#termsofservice-us';
export const CHAT_WITH_SALES_CHILI_PIPER_URL =
  'https://podium.chilipiper.com/book/inbound-ft';
export const PODIUM_LOGIN_URL = 'https://auth.podium.com';
export const MOBILE_ONBOARDING_URL = `${env.KAZAAM_URL}/setup/mobile/`;

export const CHECKOUT_URL = '/plans';
export const DORMANT_URL = '/dormant';
export const NO_ACCESS_URL = '/no-access';
export const SALES_ASSIST_URL = '/get-started';
export const TRIAL_URL = '/trials';
export const CART_URL = '/cart';
export const PLAN_CART_URL = '/plan-cart';
