import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import envVars from 'env';

import {
  CART_URL,
  CHECKOUT_URL,
  NO_ACCESS_URL,
  PAYMENT_PATHNAME,
  PLAN_CART_URL,
  SALES_ASSIST_URL,
  TRIAL_URL,
} from './constants';

const StarterUpgrade = React.lazy(
  () =>
    import(/* webpackChunkName: "StarterUpgrade" */ 'screens/StarterUpgrade')
);

const OrderConfirmation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OrderConfirmation" */ 'screens/OrderConfirmation'
    )
);

const TrialsPage = React.lazy(
  () => import(/* webpackChunkName: "TrialsPage" */ 'screens/Trials')
);

const NoAccess = React.lazy(
  () => import(/* webpackChunkName: "NoAccess" */ 'screens/NoAccess')
);

const NotFound = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ 'screens/NotFound')
);

const PricingPackaging = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ 'screens/PricingPackaging')
);

const PricingPackagingReadOnly = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PricingPackagingReadOnly" */ 'screens/PricingPackagingReadOnly'
    )
);

const FeaturesReadOnly = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeaturesReadOnly" */ 'screens/FeaturesReadOnly'
    )
);

const Checkout = React.lazy(
  () => import(/* webpackChunkName: "Checkout" */ 'screens/Checkout')
);

const Maintenance = React.lazy(
  () => import(/* webpackChunkName: "Maintenance" */ 'screens/Maintenance')
);

const SalesAssist = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SalesAssistTrialCheckout" */ 'screens/SalesAssist'
    )
);

const MockReviewInvite = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MockReviewInvite" */ 'screens/MockReviewInvite'
    )
);

const PurchaseCart = React.lazy(
  () => import(/* webpackChunkName: "PurchaseCart" */ 'screens/PurchaseCart')
);

const PurchasePlanCart = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PurchasePlanCart" */ 'screens/PurchasePlanCart'
    )
);

const RoutesComponent = () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Routes>
          {envVars.IS_MAINTENANCE_MODE ? (
            <Route element={<Maintenance />} path="*" />
          ) : (
            <>
              <Route element={<StarterUpgrade />} path={PAYMENT_PATHNAME} />
              <Route
                element={<OrderConfirmation />}
                path="/confirmation/:plan"
              />
              <Route element={<Checkout />} path={`${CHECKOUT_URL}/*`} />
              <Route element={<TrialsPage />} path={`${TRIAL_URL}/*`} />
              <Route element={<PricingPackaging />} path="/pricing" />
              <Route
                element={<PricingPackagingReadOnly />}
                path="/pricing-view"
              />
              <Route element={<FeaturesReadOnly />} path="/features-view" />
              <Route element={<MockReviewInvite />} path="/try/google-review" />
              <Route element={<SalesAssist />} path={`${SALES_ASSIST_URL}/*`} />
              <Route element={<PurchaseCart />} path={`${CART_URL}/*`} />
              <Route
                element={<PurchasePlanCart />}
                path={`${PLAN_CART_URL}/*`}
              />

              <Route element={<NoAccess />} path={NO_ACCESS_URL} />
              <Route element={<NotFound />} path="*" />
            </>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesComponent;
