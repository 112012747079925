const SELECT_PLAN_CHECKOUT_TRACKING_EVENTS = {
  productAddon: {
    phones: {
      viewed: true,
      purchase: true,
      confirmAddon: {
        clicked: true,
      },
      skip: {
        clicked: true,
      },
      updateSeatCountModal: {
        viewed: true,
        remove: {
          clicked: true,
        },
      },
    },
  },
  selectPlanCheckout: {
    formFieldFilled: true,
    clicked: true,
    viewed: true,
    error: true,
    action: true,
  },
};

export default SELECT_PLAN_CHECKOUT_TRACKING_EVENTS;
