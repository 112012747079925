window.env = window.env || {};

const getEnv = (key) => {
  return window.env[key] || process.env[key];
};

const envVars = {
  ACCOUNTS_URL: getEnv('REACT_APP_ACCOUNTS_URL') || 'http://localhost:4100',
  AMPLITUDE_TOKEN: getEnv('REACT_APP_AMPLITUDE_TOKEN'),
  AMPLITUDE_EXPERIMENT_TOKEN: getEnv('REACT_APP_AMPLITUDE_EXPERIMENT_TOKEN'),
  APP_VERSION: getEnv('REACT_APP_VERSION') || '1',
  APPLE_AUTH_URI:
    getEnv('REACT_APP_APPLE_AUTH_URI') ||
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  APPLE_CLIENT_ID: getEnv('REACT_APP_APPLE_CLIENT_ID'),
  CHECKOUT_URL: getEnv('REACT_APP_CHECKOUT_URL'),
  GOOGLE_ACCOUNT_URI:
    getEnv('REACT_APP_GOOGLE_ACCOUNT_URI') ||
    'https://accounts.google.com/gsi/client',
  GOOGLE_CLIENT_ID: getEnv('REACT_APP_GOOGLE_CLIENT_ID'),
  GOOGLE_MAPS_API_KEY:
    getEnv('REACT_APP_GOOGLE_MAPS_API_KEY') ||
    'AIzaSyBZ73tu7CtzNDZVGjwGO9C6u4J6h-dBqaU',
  IPSTACK_TOKEN:
    getEnv('REACT_APP_IPSTACK_TOKEN') || 'aace0a7cfd74b5725e0a97238fa93fa9',
  IPSTACK_URL: getEnv('REACT_APP_IPSTACK_URL') || 'https://api.ipstack.com',
  KAZAAM_URL: getEnv('REACT_APP_KAZAAM_URL'),
  RECAPTCHA_SITE_KEY: getEnv('REACT_APP_RECAPTCHA_SITE_KEY'),
  SIGNUP_URL: getEnv('REACT_APP_SIGNUP_URL'),
  STRIPE_PUBLIC_KEY: getEnv('REACT_APP_STRIPE_PUBLIC_KEY'),
  SUBSCRIPTIONS_URI:
    getEnv('REACT_APP_SUBSCRIPTIONS_URI') || 'http://localhost:1985',
  IS_MAINTENANCE_MODE:
    (getEnv('REACT_APP_IS_MAINTENANCE_MODE') || 'false') === 'true',
  MAINTENANCE_ENDS_AT: getEnv('REACT_APP_MAINTENANCE_ENDS_AT'),
};

export default envVars;
