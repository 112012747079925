export const getCookies = () => {
  return document.cookie
    .split('; ')
    .map((kv) => kv.split('='))
    .reduce((map, arr) => {
      map[arr[0]] = arr[1];
      return map;
    }, {});
};

export const getTrackingCookies = () => {
  const allCookies = getCookies();

  return {
    firstUtmMedium: allCookies['first-utm-medium'],
    googleClientId: allCookies['_ga']?.split('.')?.slice(-2)?.join('.'),
    partnerId: allCookies['partner-id'],
    salesforceCampaignId: allCookies['scid'],
    utmMediumFromCookies: allCookies['utm-medium'],
  };
};
