import isMocked from 'lib/isMocked';
import isQABot from 'lib/isQABot';

import setupTracking from './amplitude';
import addDatadog from './datadog';
import addFullstory from './fullstory';

const ThirdPartyScripts = () => {
  // eslint-disable-next-line no-console
  console.debug('[ThirdPartyScripts] start loading third party scripts');

  if (isQABot() || isMocked()) {
    return null;
  }
  addFullstory();
  setupTracking();
  addDatadog();

  // eslint-disable-next-line no-console
  console.debug('[ThirdPartyScripts] end loading third party scripts');

  return null;
};

export default ThirdPartyScripts;
