const TRIALS_TRACKING_EVENTS = {
  signup: {
    action: true,
    clicked: true,
    error: true,
    viewed: true,
    formFieldFilled: true,
  },
  accountProvisioning: {
    duration: true,
  },
};

export default TRIALS_TRACKING_EVENTS;
